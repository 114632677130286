import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/reportes/ventas";
import { print } from '../../../../../redux/modules/print/print'
import ListadoVentas from "./ListadoVentas";
import usuario from '../../../../../redux/modules/usuario/usuario';


const ms2p = (state) => {
    // const venta_impresion_data = state.reporte_ventas.venta_impresion;
    // //  Datos para la factura perosonalizada
    // let datos_venta= {}

    return {
        ...state.reporte_ventas,
        factura_personalizada: state.usuario.factura_personalizada,
        formato_factura: state.usuario.formato_factura,
        utiliza_fel: state.usuario.utiliza_fel,
        conf_ventas_al_credito: state.usuario.conf_ventas_al_credito,
        print_state: state.print,
        permiso_grafica_ventas: state.usuario.me.permiso_grafica_ventas,
        conf_tipo_negocio:
            state.configuracion && state.configuracion.config
                ? state.configuracion.config.conf_tipo_negocio
                : false, 
        // datos_venta
    };
};

const md2p = {
    ...actions,
    print: (name) => (dispatch) => dispatch(print(name)),
    impresionGeneral: (id, section) => (dispatch) =>
        dispatch(actions.impresionGeneral(id, section)),
};

export default connect(ms2p, md2p)(ListadoVentas);

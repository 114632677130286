import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { api } from "api";
import moment from "moment";
import Swal from "sweetalert2";
import { tipo_movimientos } from "utility/variables";
import { ToastStore } from "react-toasts";
import { actions as printActions } from "../print/print";

const endpoint = "historial_ventas";
const endpoint_fel = "factura_electronica";

// ------------------------------------
// Constants
// ------------------------------------

export const DATA = "R_VENTAS_DATA";
export const DATA_GRAFICA = "DATA_GRAFICA";
export const DETALLE = "R_VENTAS_DETALLE";
export const SORT = "R_VENTAS_SORT";
export const PAGE = "R_VENTAS_PAGE";
export const FECHA_INICIAL = "R_VENTAS_FECHA_INICIAL";
export const FECHA_FINAL = "R_VENTAS_FECHA_FINAL";
export const LOADER = "R_VENTAS_LOADER";
export const LOADER_GRAFICA = "R_VENTAS_LOADER_GRAFICA";
export const USUARIO = "R_VENTAS_USUARIO";
export const CLIENTE = "R_VENTAS_CLIENTE";
export const ESTADO = "R_VENTAS_ESTADO";
export const SET_IMPRESION = "R_VENTAS_SET_IMPRESION";
export const SET_RECIBO_CAJA_IMPRESION = "R_VENTAS_SET_RECIBO_CAJA_IMPRESION";
export const ESTADO_DESCARGA = "R_VEMTAS_ESTADO_DESCARGA";
export const ESTADO_FACTURA = "R_VEMTAS_ESTADO_FACTURA";
export const FILTRO_FECHA = "R_VENTAS_FILTRO_FECHA";
export const BUSCADOR = "R_VENTAS_BUSCADOR";

// ------------------------------------
// Actions
// ------------------------------------

export const dataGraficaVentas = () => (dispatch, getStore) => {
    dispatch(setLoaderGrafica(true));
    let now = new Date();
    let year = now.getFullYear();

    api.get(`${endpoint}/grafica`, { anio_actual: year, anio_pasado: year - 1 })
        .then((response) => {
            if (response) dispatch(setDataGrafica(response));
        })
        .catch((error) => {
            Swal("ERROR", "Ha ocurrido un error.", "error");
        })
        .finally(() => {
            dispatch(setLoaderGrafica(false));
        });
};

export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const utiliza_fel = store.usuario.utiliza_fel;
    const fecha_inicial = store.reporte_ventas.fecha_inicial;
    const fecha_final = store.reporte_ventas.fecha_final;
    const search = store.reporte_ventas.search;
    const params = { fecha_final, fecha_inicial, page, search };
    const usuario = store.reporte_ventas.usuario;
    const cliente = store.reporte_ventas.cliente;
    const filtro_fecha = store.reporte_ventas.filtro_fecha;
    params.tipo = tipo_movimientos.VENTA;
    params.ordering = store.reporte_ventas.ordering;
    params.activo = store.reporte_ventas.estado;
    params.filtro_por = filtro_fecha;
    if (usuario) params.usuario_id = usuario.id;
    if (cliente) params.cliente_id = cliente.id;
    if (utiliza_fel) {
        params.estado_factura = store.reporte_ventas.estado_factura;
    }
    api.get(`${endpoint}`, params)
        .catch((err) => {
            Swal("ERROR", "Ha ocurrido un error.", "error");
        })
        .then((resp) => {
            if (resp) {
                dispatch(setData(resp));
                dispatch(setPage(page));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`)
        .catch((err) => {
            dispatch(push("/reporte_ventas"));
        })
        .then((data) => {
            if (data) dispatch(setDetalle(data));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const anular = (id, motivo) => (dispatch, getStore) => {
    const store = getStore();
    const page = store.reporte_ventas.page;
    dispatch(setLoader(true));
    api.put(`${endpoint}/anulacion`, { id, motivo })
        .catch((err) => {
            dispatch(push("/reporte_ventas"));
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "No se ha podido anular la venta, intente más tarde.",
                    "error"
                );
            }
        })
        .then((data) => {
            if (data) {
                if (data.id_factura) {
                    Swal(
                        "¡Anulación de factura electrónica en proceso!",
                        "Se esta generando la anulación de la factura, espere por favor",
                        "info"
                    );
                    dispatch(consultarEstadoFEL(data.id_factura));
                } else {
                    ToastStore.success("Datos almacenados correctamente");
                    dispatch(listar(page));
                }
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
export const setUsuario = (value) => (dispatch) => {
    dispatch(_setUsuario(value));
    dispatch(listar());
};

export const setCliente = (value) => (dispatch) => {
    dispatch(_setCliente(value));
    dispatch(listar());
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const reporte_ventas = store.reporte_ventas;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(reporte_ventas.fecha_final);
        if (fecha_final >= fecha_inicial) dispatch(setFechaInicial(value));
    } else {
        const fecha_inicial = new Date(reporte_ventas.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) dispatch(setFechaFinal(value));
    }
    dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.reporte_ventas.page;
    dispatch(listar(page));
};

export const estadoChange = (estado) => (dispatch) => {
    dispatch(_setEstado(estado));
    dispatch(listar());
};

export const seleccionImpresion = (id, seccionImpresion) => (dispatch) => {
    dispatch(setLoader(true));
    // api.get(`${endpoint}/${id}`).catch((err) => {
    //     dispatch(push('/reporte_ventas'));
    // }).then((data) => {
    //     if (data){

    //         //  SE ACTUALIZA EL MONTO RECIBIDO DE LA COMPRA
    //         let data_venta = data;
    //         let data_movimiento = data.movimiento
    //         const vuelto = data_movimiento.vuelto_efectivo ? data_movimiento.vuelto_efectivo : 0;
    //         data_movimiento.monto_efectivo = data_movimiento.monto_efectivo + vuelto;
    //         data_venta.movimiento = data_movimiento
    //         dispatch(setVentaImpresion(data_venta))
    //         printActions.print(seccionImpresion)
    //     }
    // }).finally(() => {
    //     dispatch(setLoader(false));
    // });
    dispatch(setVentaImpresion(null));
    let tipo = "comprobante";
    if (seccionImpresion == "reporteVentasReimpresionReciboCaja") {
        tipo = "recibo_caja";
    }
    api.get(`${endpoint}/reimpresion_venta`, { id, tipo })
        .catch((err) => {
            dispatch(push("/reporte_ventas"));
            dispatch(setVentaImpresion(null));
            dispatch(setReciboCajaImpresion(null));
        })
        .then((resp) => {
            if (resp) {
                let data_impresion = resp;
                let vuelto = 0;
                if (data_impresion.length === undefined) {
                    vuelto = data_impresion.vuelto_efectivo
                        ? data_impresion.vuelto_efectivo
                        : 0;
                    data_impresion.monto_efectivo =
                        data_impresion.monto_efectivo + vuelto;
                }
                if (seccionImpresion == "reporteVentasReimpresionReciboCaja") {
                    dispatch(setVentaImpresion(null));
                    dispatch(setReciboCajaImpresion(data_impresion));
                    dispatch(printActions.print(seccionImpresion));
                } else {
                    dispatch(setReciboCajaImpresion(null));
                    dispatch(setVentaImpresion(data_impresion));
                    dispatch(printActions.print(seccionImpresion));
                }
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

//-------------------------------------
// RETIMBRADO DE FACTURA
//_____________________________________

export const retimbradoFEL = (id_venta) => (dispatch) => {
    dispatch(setLoader(true));

    api.post(`${endpoint_fel}/rieintento_fel`, { id_venta })
        .catch((err) => {
            dispatch(push("/punto_de_venta"));
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal("ERROR", "Error al generar factura electrónica", "error");
            }
        })
        .then((data) => {
            if (data.id_factura) {
                dispatch(consultarEstadoFEL(data.id_factura));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const consultarEstadoFEL = (id_factura) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const { page } = store.reporte_ventas;

    api.get("factura_electronica/verificar_fel", { id_factura })
        .catch((err) => {
            Swal(
                "ERROR",
                "Error al consultar el estado del proceso FEL",
                "error"
            );
        })
        .then((resp) => {
            status = resp.estado_factura;
            if (status === "proceso") {
                setTimeout(() => {
                    dispatch(consultarEstadoFEL(id_factura));
                }, 1000);
            } else if (status === "facturado") {
                Swal(
                    "Éxito",
                    "Factura electrónica registrada correctamente",
                    "success"
                ).then(() => {
                    setTimeout(() => {
                        dispatch(listar(page));
                    }, 500);
                });
                dispatch(setLoader(false));
            } else if (status === "anulada") {
                Swal(
                    "Éxito",
                    "Venta y factura electrónica anuladas correctamente",
                    "success"
                ).then(() => {
                    setTimeout(() => {
                        dispatch(listar(page));
                    }, 500);
                });
                dispatch(setLoader(false));
            } else {
                const msj = resp.detail
                    ? `Hubo un error con FEL, ${resp.detail}, intenta más tarde`
                    : "Hubo un error con FEL, intenta más tarde";
                Swal("ERROR", msj, "error");
                dispatch(listar(page));
                dispatch(setLoader(false));
            }
        });
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const utiliza_fel = store.usuario.utiliza_fel;
    const fecha_inicial = store.reporte_ventas.fecha_inicial;
    const fecha_final = store.reporte_ventas.fecha_final;
    const search = store.reporte_ventas.search;
    const params = { fecha_final, fecha_inicial, search };
    const usuario = store.reporte_ventas.usuario;
    const cliente = store.reporte_ventas.cliente;
    const filtro_fecha = store.reporte_ventas.filtro_fecha;
    params.tipo = tipo_movimientos.VENTA;
    params.ordering = store.reporte_ventas.ordering;
    params.activo = store.reporte_ventas.estado;
    params.filtro_por = filtro_fecha;
    if (usuario) params.usuario_id = usuario.id;
    if (cliente) params.cliente_id = cliente.id;
    if (utiliza_fel) {
        params.estado_factura = store.reporte_ventas.estado_factura;
    }
    dispatch(setEstadoDescarga(true));
    api.get(`${endpoint}/descargar_excel`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get(`${endpoint}/estado_descarga`, { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Listado_clientes.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

export const estadoFacturaChange = (estado) => (dispatch) => {
    dispatch(setEstadoFactura(estado));
    dispatch(listar());
};

export const filtroFechaChange = (filtro_fecha) => (dispatch) => {
    dispatch(_setFiltroFechaChange(filtro_fecha));
    dispatch(listar());
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const impresionGeneral = (id, seccionImpresion) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setVentaImpresion(null));

    dispatch(printActions.print(seccionImpresion));
    dispatch(setLoader(false));
};

//-------------------------------------

// ------------------------------------
// PureActions
// ------------------------------------

export const setDataGrafica = (data_grafica) => ({
    type: DATA_GRAFICA,
    data_grafica,
});

export const setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});

export const setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setDetalle = (detalle) => ({
    type: DETALLE,
    detalle,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setLoaderGrafica = (loader_grafica) => ({
    type: LOADER_GRAFICA,
    loader_grafica,
});

export const _setUsuario = (usuario) => ({
    type: USUARIO,
    usuario,
});

export const _setCliente = (cliente) => ({
    type: CLIENTE,
    cliente,
});

export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const _setEstado = (estado) => ({
    type: ESTADO,
    estado,
});

export const setVentaImpresion = (venta_impresion) => ({
    type: SET_IMPRESION,
    venta_impresion,
});

export const setReciboCajaImpresion = (recibo_caja_impresion) => ({
    type: SET_RECIBO_CAJA_IMPRESION,
    recibo_caja_impresion,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});
const setEstadoFactura = (estado_factura) => ({
    type: ESTADO_FACTURA,
    estado_factura,
});

export const _setFiltroFechaChange = (filtro_fecha) => ({
    type: FILTRO_FECHA,
    filtro_fecha,
});

export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});

export const actions = {
    leer,
    listar,
    setFecha,
    setUsuario,
    sortChange,
    dataGraficaVentas,
    setCliente,
    anular,
    buscar,
    estadoChange,
    seleccionImpresion,
    retimbradoFEL,
    descargarListado,
    estadoFacturaChange,
    filtroFechaChange,
    impresionGeneral, // Add this to the exported actions
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_GRAFICA]: (state, { data_grafica }) => {
        return {
            ...state,
            data_grafica,
        };
    },
    [DETALLE]: (state, { detalle }) => {
        return {
            ...state,
            detalle,
        };
    },
    [FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            fecha_inicial,
        };
    },
    [FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            fecha_final,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LOADER_GRAFICA]: (state, { loader_grafica }) => {
        return {
            ...state,
            loader_grafica,
        };
    },
    [USUARIO]: (state, { usuario }) => {
        return {
            ...state,
            usuario,
        };
    },
    [CLIENTE]: (state, { cliente }) => {
        return {
            ...state,
            cliente,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ESTADO]: (state, { estado }) => {
        return {
            ...state,
            estado,
        };
    },
    [SET_IMPRESION]: (state, { venta_impresion }) => {
        return {
            ...state,
            venta_impresion,
        };
    },
    [SET_RECIBO_CAJA_IMPRESION]: (state, { recibo_caja_impresion }) => {
        return {
            ...state,
            recibo_caja_impresion,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
    [ESTADO_FACTURA]: (state, { estado_factura }) => {
        return {
            ...state,
            estado_factura,
        };
    },
    [FILTRO_FECHA]: (state, { filtro_fecha }) => {
        return {
            ...state,
            filtro_fecha,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    detalle: {},
    page: 1,
    data_grafica: {},
    usuario: null,
    cliente: null,
    fecha_inicial: moment().format("YYYY-MM-DD"),
    ordering: "",
    fecha_final: moment().format("YYYY-MM-DD"),
    loader: false,
    loaderGrafica: false,
    estado: true,
    venta_impresion: null,
    recibo_caja_impresion: null,
    estado_descarga: false,
    estado_factura: "",
    filtro_fecha: "fecha_venta",
    search: "",
};

export default handleActions(reducers, initialState);
